import React from 'react'

export const renderBackground = (object) => {
  if (!object._linkType && object.url) {
    return (
      <img src={object.url} alt="" />
    )
  }

  switch(object._linkType) {
    case 'Link.file':
      return (
        <video src={object.url} autoPlay playsInline muted loop />
      )
    case 'Link.image':
      return (
        <img src={object.url} alt="" />
      )
    default:
      return null
  }
}