import React from "react"
import PropTypes from "prop-types"

const ResponsiveImage = ({ src, width, height, classes, alt, widths, parentFit, cropRatio }) => {
  const aspectRatio = width & height ? height / width : null

  return (
    <img
      src={width && height ? `data:image/svg+xml;utf8,%3Csvg xmlns='http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox='0 0 ${width} ${height}'%3E%3C/svg%3E` : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
      data-src={cropRatio ? `${src}&ar=${cropRatio}&fit=crop` : src}
      data-srcset={widths ? 
        widths.map((w, index) => {
          return (
            cropRatio ? `${src}&w=${w}&ar=${cropRatio}&fit=crop ${Math.round(aspectRatio < 1 ? w : w / aspectRatio)}w` : `${src}&w=${w}&h=${w}&fit=clip ${Math.round(aspectRatio < 1 ? w : w / aspectRatio)}w`
          )
        }).join()
      :
        null
      }
      data-sizes={widths ? "auto" : null}
      data-parent-fit={parentFit}
      data-aspectratio={aspectRatio}
      alt={alt ? alt : ''}
      className={`lazyload ${classes}`}
    />
  )
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  classes: PropTypes.string,
  alt: PropTypes.string,
  widths: PropTypes.array,
  parentFit: PropTypes.string, 
  cropRatio: PropTypes.string
}

ResponsiveImage.defaultProps = {
  src: '',
  width: null,
  height: null,
  classes: '',
  alt: '',
  widths: null,
  parentFit: null,
  cropRatio: null
}

export default ResponsiveImage
